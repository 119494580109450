<template>
  <v-layout column>
    <LabelDateField
      label="Data porodu"
      placeholder="Wybierz..."
      :value.sync="value.date"
      :disabled="disabled"
      noMargins
      hide-details
    ></LabelDateField>
    <v-layout :class="['mt-3', $vuetify.breakpoint.smAndDown && 'column']"
      ><LabelTextField
        label="Masa dziecka (g)"
        v-mask="'#####'"
        placeholder="W gramach..."
        :value.sync="value.mass"
        :disabled="disabled"
        noMargins
        hide-details
      ></LabelTextField
      ><LabelTextField
        label="Długość dziecka (cm)"
        placeholder="W centymentrach..."
        v-mask="'#####'"
        :value.sync="value.length"
        :disabled="disabled"
        :class="$vuetify.breakpoint.smAndDown ? 'mt-3' : 'ml-4'"
        noMargins
        hide-details
      ></LabelTextField
    ></v-layout>
    <v-layout column class="mt-3">
      <DefaultSubtitle class="mb-3">Rodzaj porodu</DefaultSubtitle>
      <v-radio-group
        v-model="value.pregnancy_type"
        :class="['mt-1 zg-text-control']"
        :row="!$vuetify.breakpoint.smAndDown"
        :disabled="disabled"
        hide-details
      >
        <v-radio
          class="zg-text-control"
          label="Siłami natury"
          :value="0"
        ></v-radio>
        <v-radio
          class="zg-text-control"
          label="Cesarskie cięcie"
          :value="1"
        ></v-radio>
        <v-radio
          class="zg-text-control"
          label="Kleszczowy i próżniowy"
          :value="2"
        ></v-radio>
      </v-radio-group>
    </v-layout>
    <v-layout column class="mt-3">
      <DefaultSubtitle class="mb-3">Notatki</DefaultSubtitle>
      <HTMLTextArea
        placeholder="Wpisz..."
        :value.sync="value.reason"
        :disabled="disabled"
        dense
        hideLabel
      ></HTMLTextArea>
    </v-layout>
  </v-layout>
</template>

<script>
export default {
  props: {
    value: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DefaultSubtitle: () => import("@/components/text/DefaultSubtitle"),
    LabelDateField: () => import("@/components/LabelDateField"),
    LabelTextField: () => import("@/components/LabelTextField"),
    HTMLTextArea: () => import("@/components/inputs/HTMLTextArea"),
  },
};
</script>

<style scoped>
.zg-text-control ::v-deep label {
  color: rgba(0, 0, 0, 0.87);
}
</style>